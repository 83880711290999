import axios from "axios";
import actionTypes, { BASE_URL_SALLA } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";
import { toast } from "react-toastify";

export const sendOTPByMobile = ({ mobile, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_SEND_OTP_LOADER,
      payload: true,
    });

    axios
      .post(`${BASE_URL_SALLA}forget/password/otp`, { mobile: "+" + mobile })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OTP_BY_MOBILE,
          payload: { ...data.data, mobile },
        });
        dispatch({
          type: actionTypes.SET_SEND_OTP_LOADER,
          payload: false,
        });
        history.replace(
          `${
            getState().websiteLanguage.language
          }/sallaforgotpasswordverifycode?mid=${getState().login.mid}`
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_SEND_OTP_LOADER,
          payload: false,
        });

        errorMessageHandler(error);
      });
  };
};

export const verifyOTP = ({ otp, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_VERIFY_OTP_LOADER,
      payload: true,
    });

    axios
      .post(`${BASE_URL_SALLA}forget/password/otp/verify`, {
        otp,
        access_token: getState().forgotP.access_token,
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_USER_INFO,
          payload: data.data,
        });
        dispatch({
          type: actionTypes.SET_VERIFY_OTP_LOADER,
          payload: false,
        });
        history.replace(
          `/${
            getState().websiteLanguage.language
          }/sallacreatenewpaassword?mid=${getState().login.mid}`
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_VERIFY_OTP_LOADER,
          payload: false,
        });

        errorMessageHandler(error);
      });
  };
};

export const passwordUpdate = ({
  email,
  access_token,
  password,
  new_password_confirmation,
  history,
}) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SAVE_UPDATE_PASS_LOADER,
      payload: true,
    });

    axios
      .post(`${BASE_URL_SALLA}forget/password/otp/verify`, {
        email,
        access_token,
        password,
        new_password_confirmation,
      })
      .then((res) => res.data)
      .then((data) => {
        history.replace(
          `/${getState().websiteLanguage.language}/sallabusinessselect?mid=${
            getState().login.mid
          }`,
          {
            replace: true,
          }
        );
        dispatch({
          type: actionTypes.SAVE_UPDATE_PASS_LOADER,
          payload: false,
        });
        toast(data.data.message, {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
          theme: "light",
          type: "success",
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SAVE_UPDATE_PASS_LOADER,
          payload: false,
        });

        errorMessageHandler(error);
      });
  };
};
