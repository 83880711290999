import Routes from "./routers/Routes";
import React from "react";
import { ToastContainer } from "react-toastify";
import { GoogleOAuthProvider } from "@react-oauth/google";

// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "react-toastify/dist/ReactToastify.css";

//Modal Video
import "react-modal-video/scss/modal-video.scss";

const App = () => {
  return (
    <>
      <GoogleOAuthProvider clientId="707133061105-plvt6pc6s5mho6mivmq2k401vtjg43uc.apps.googleusercontent.com">
        <ToastContainer />
        <Routes />
      </GoogleOAuthProvider>
    </>
  );
};

export default App;
