import actionsTypes from "../actionTypes";

const defaultState = {
  loading: false,
  business_list: [],
  saveloading: false,
  business_name: "im io test",
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 */
const businessReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionsTypes.GET_LIST_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actionsTypes.SET_BUSINESS_LIST: {
      return {
        ...state,
        business_list: action.payload,
      };
    }
    case actionsTypes.SET_SAVE_LOADER: {
      return {
        ...state,
        saveloading: action.payload,
      };
    }

    case actionsTypes.SET_BSN_NAME: {
      return {
        ...state,
        business_name: action.payload,
      };
    }

    default:
      return state;
  }
};

export default businessReducer;
