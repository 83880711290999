import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const errorMessageHandler = async (err, translate) => {
  if (
    err.errorStatus === 401 ||
    err.errorStatus === "401"
    // ||
    // (err.response && err.response.status === 401)
  ) {
    let errorMessage = "Something went wrong!";

    if (translate) {
      errorMessage = translate[errorMessage];
    }
    toast(errorMessage, {
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: false,
      theme: "light",
      // toastId: errorMessage,
      type: "error",
    });
    // alert("Please logout and login again");
  } else if (err.response && err.response.status === 429) {
    return;
  } else if (
    err.errorStatus === 502 ||
    (err.response && err.response.status === 502)
  ) {
    alert("Something went wrong try again");
  } else {
    let errorMessage = null;
    // console.log("err.response", JSON.stringify(err.response, null, 2));
    if (err.response && err.response.data && err.response.data.data) {
      if (
        typeof err?.response?.data?.data !== "string" &&
        Object.keys(err?.response?.data?.data)?.length > 0
      ) {
        // iterate over the error data object
        for (const key in err?.response?.data?.data) {
          errorMessage = err?.response?.data?.data?.[key]?.[0];
          if (typeof errorMessage === "string" && errorMessage?.includes(".")) {
            errorMessage = errorMessage?.split(".").join("");
          }
          if (
            typeof errorMessage !== "string" &&
            Object.keys(errorMessage)?.length > 0
          ) {
            for (const errKey in errorMessage) {
              errorMessage = errorMessage[errKey][0];
              if (errorMessage?.includes(".")) {
                errorMessage = errorMessage.split(".").join("");
              }
            }
          }
          break;
        }
      } else if (typeof err.response?.data?.data === "string") {
        errorMessage = err.response?.data?.data;
      }
    } else if (
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.response
    ) {
      errorMessage =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.response;
    }
    console.log("errorMessage", errorMessage);
    if (translate) {
      errorMessage = translate[errorMessage];
    }

    if (errorMessage !== "Unauthenticated.") {
      // alert(errorMessage);
      toast(errorMessage, {
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
        toastId: errorMessage,
        type: "error",
      });
    }
    return errorMessage;
  }
};
