import axios from "axios";
import actionTypes, { BASE_URL_WEBSITE } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";
import { setAuthToken } from "./login.actions";
import { checkBusinessVerified } from "./optimizeregister.actions";
import { toast } from "react-toastify";

export const login = ({ email, password, callback, translate }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_WEBSITE}website/login`, { email, password })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        // dispatch({
        //   type: actionTypes.LOGIN,
        //   payload: data.data,
        // });
        return dispatch(setAuthToken(data.data.access_token));
      })
      .then((data) => {
        dispatch(businessList());
      })
      .then((data) => {
        callback && callback();
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(err, translate);
      });
  };
};

export const businessList = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_BUSINESS_LIST_LOADER,
      payload: true,
    });

    return axios
      .get(`${BASE_URL_WEBSITE}website/users/business/show`)
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_BUSINESS_LIST_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_BUSINESS_LIST,
          payload: data.data,
        });
        return data.data;
      })
      .then((list) => {
        list?.length > 0 && dispatch(fetchBusinessDetail(list[0]?.id));
        return list;
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_BUSINESS_LIST_LOADER,
          payload: false,
        });
        return [];
      });
  };
};

export const fetchBusinessDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_BUSINESS_DETAIL_LOADER,
      payload: true,
    });

    axios
      .get(`${BASE_URL_WEBSITE}website/business/${id}`)
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.GET_BUSINESS_DETAIL_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_BUSINESS_DETAIL,
          payload: data.data,
        });
        dispatch(checkBusinessVerified(id));
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.GET_BUSINESS_DETAIL_LOADER,
          payload: false,
        });
      });
  };
};

export const logout = ({ history }) => {
  return (dispatch) => {
    dispatch(setAuthToken(null));
    history.push(`/signin`, { replace: true });
  };
};

export const loginwithSMSOtp = ({ mobile, email, callback }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });

    axios
      .post(`${BASE_URL_WEBSITE}website/login/otp`, { mobile, email })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_OTP_USER_INFO,
          payload: { ...data.data, email, mobile },
        });
        callback();
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
      });
  };
};

export const verifyLoginWithSMSOtp = ({ email, mobile, otp, callback }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_WEBSITE}website/login/otp/verify`, {
        email,
        mobile,
        otp,
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        // dispatch({
        //   type: actionTypes.LOGIN,
        //   payload: data.data,
        // });
        return dispatch(setAuthToken(data.data.access_token));
      })
      .then((data) => {
        dispatch(businessList());
      })
      .then((data) => {
        callback && callback();
      })
      .catch((error) => {
        errorMessageHandler(error);
      });
  };
};

export const forgotPasswordResetByEmail = ({ email, callback }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });

    return axios
      .post(`${BASE_URL_WEBSITE}website/password/email`, { email })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        callback(data.message);
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
      });
  };
};
