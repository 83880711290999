import axios from "axios";
import actionTypes, { BASE_URL_ZID } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";

export const getBusinessList = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.GET_LIST_LOADER,
      payload: true,
    });

    axios
      .get(`${BASE_URL_ZID}business`)
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_BUSINESS_LIST,
          payload: data.data,
        });
        dispatch({
          type: actionTypes.GET_LIST_LOADER,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_LIST_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const connectSallaToBusiness = ({ history, info }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_SAVE_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_ZID}assign/businesstozidstore`, info)
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_SAVE_LOADER,
          payload: false,
        });
        history.push(
          `/${getState().websiteLanguage.language}/zidaccountlinksuccess?$mid=${
            getState().login.mid
          }`,
          { replace: true }
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: actionTypes.SET_SAVE_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const setLocalBusinessName = ({ business_name }) => {
  return (dispatch) =>
    dispatch({ type: actionTypes.SET_BSN_NAME, payload: business_name });
};
