import axios from "axios";
import actionTypes, { BASE_URL_WEBSITE } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";
import { businessList } from "./optimizelogin.actions";

export const verifyEmail = ({ email, history, translate }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_EMAIL_LOADER,
      payload: true,
    });
    return axios
      .post(`${BASE_URL_WEBSITE}validate/website/email`, { email })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { email },
        });
        history.push(`/signup-businessdetails`, { replace: true });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
        errorMessageHandler(error, translate);
      });
  };
};

export const verifyBusinessName = ({ name, history, translate }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_BUSINESS_NAME_LOADER,
      payload: true,
    });
    dispatch({
      type: actionTypes.SET_BUSINESS_NAME_AVAILABLE,
      payload: null,
    });
    return axios
      .post(`${BASE_URL_WEBSITE}validate/website/businessname`, { name })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_BUSINESS_NAME_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_BUSINESS_REGISTER_INFO,
          payload: { name },
        });

        dispatch({
          type: actionTypes.SET_BUSINESS_NAME_AVAILABLE,
          payload: data.message,
        });
      })
      .catch(async (error) => {
        dispatch({
          type: actionTypes.SET_BUSINESS_NAME_LOADER,
          payload: false,
        });
        const msg = await errorMessageHandler(error, translate);
        dispatch({
          type: actionTypes.SET_BUSINESS_NAME_AVAILABLE,
          payload: msg,
        });
      });
  };
};

export const verifyBusinessWebsite = ({ business_website, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_BUSINESS_WEBSITE_LOADER,
      payload: true,
    });
    dispatch({
      type: actionTypes.SET_BUSINESS_WEBSITE_AVAILABLE,
      payload: null,
    });
    return axios
      .post(`${BASE_URL_WEBSITE}validate/website/businesswebsite`, {
        url: business_website,
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_BUSINESS_WEBSITE_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_BUSINESS_REGISTER_INFO,
          payload: { business_website },
        });

        dispatch({
          type: actionTypes.SET_BUSINESS_WEBSITE_AVAILABLE,
          payload: data.message,
        });
      })
      .catch(async (error) => {
        dispatch({
          type: actionTypes.SET_BUSINESS_WEBSITE_LOADER,
          payload: false,
        });
        const msg = await errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_BUSINESS_WEBSITE_AVAILABLE,
          payload: msg,
        });
      });
  };
};
/**
* 
* @param {*} info { 
* 
* "type":"SME",
*  "category":"Home Business",
 *   "name":"testBusines",
 *   "country_id":"9",
 *   "business_website":"https://optimizeapp.com",
 *  "lang":"en"
 *  // "token"==>for captcha
 * referral_code
} 
 * @returns 
 */
export const businessRegister = ({ info, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_EMAIL_LOADER,
      payload: true,
    });
    return axios
      .post(`${BASE_URL_WEBSITE}website/register`, { ...info })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_BUSINESS_REGISTER_INFO,
          payload: info,
        });
        history && history();
        // history.push(`/signup-userdetails`, { replace: true });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const validateMobile = ({ mobile }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_MOBILE_VALIDATION_LOADER,
      payload: true,
    });
    dispatch({
      type: actionTypes.SET_MOBILE_NUMBER_AVAILABLE,
      payload: "",
    });
    return axios
      .post(`${BASE_URL_WEBSITE}validate/website/phonenumber`, { mobile })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_MOBILE_VALIDATION_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_MOBILE_NUMBER_AVAILABLE,
          payload: data.message,
        });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { mobile },
        });
      })
      .catch(async (error) => {
        dispatch({
          type: actionTypes.SET_MOBILE_VALIDATION_LOADER,
          payload: false,
        });
        const errorMsg = await errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_MOBILE_NUMBER_AVAILABLE,
          payload: errorMsg,
        });
      });
  };
};
export const saveLocalUserInfo = ({ info, callback }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_USER_REGISTER_INFO,
      payload: info,
    });
    callback && callback();
  };
};

export const createUser = ({ info, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_EMAIL_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_WEBSITE}website/user/create`, { ...info })
      .then((res) => res.data)
      .then((data) => {
        console.log("createuser", data);
        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: data.data,
        });
      })
      .then(() => {
        return dispatch(
          businessRegister({
            info: {
              ...getState().optimizeregister.business,
              fbp: info.fbp,
              fbc: info.fbc,
              event_id: info.event_id,
              url: info.url,
              token: info.token,
            },
            history: () => {
              dispatch(
                sendOTPBySMS({ mobile: info.mobile, callback: callback })
              );
            },
          })
        );
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
      });
  };
};

export const saveBusinessInfoLocal = ({ info, callback }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_BUSINESS_REGISTER_INFO,
      payload: info,
    });
    callback && callback();
  };
};

/**
 *
 * @param {*} info is object of user aand response token having access_token
 * history navigation to redirect
 * @returns
 */
export const loginWithGoogle = ({ info, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(
        `${BASE_URL_WEBSITE}login/auth/google/callback?source=oa-main-website`,
        {
          ...info,
        }
      )
      .then((res) => res.data)
      .then((data) => {
        dispatch(setAuthToken(data.data.access_token));
      })
      .then(async () => {
        // logic to check if there is business associate then sign in and take inside the app other wose to registration
        const business_List = await dispatch(businessList());
        return business_List;
      })
      .then((business_List) => {
        dispatch(getUserProfile("google"));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.push(
          business_List?.length === 0
            ? `/signup-businessdetails?mode=google`
            : `/business-status`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const loginWithFacebook = ({ tokenResponse, userInfo, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(
        `${BASE_URL_WEBSITE}login/auth/facebook/callback?source=oa-main-website&lang=en`,
        {
          ...tokenResponse,
          source: "oa-main-website",
          user: { ...userInfo },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        dispatch(setAuthToken(data.data.access_token));
      })
      .then(async () => {
        // logic to check if there is business associate then sign in and take inside the app other wose to registration
        const business_List = await dispatch(businessList());
        return business_List;
      })
      .then((business_List) => {
        dispatch(getUserProfile("facebook"));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.push(
          business_List?.length === 0
            ? `/signup-businessdetails?mode=facebook`
            : `/business-status`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const loginWithApple = ({ info, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(
        `${BASE_URL_WEBSITE}login/auth/apple/callback?source=oa-main-website&lang=en`,
        {
          ...info,
        }
      )
      .then((res) => res.data)
      .then((data) => {
        return dispatch(setAuthToken(data.data.access_token));
      })
      .then(async () => {
        // logic to check if there is business associate then sign in and take inside the app other wose to registration
        const business_List = await dispatch(businessList());
        return business_List;
      })
      .then((business_List) => {
        dispatch(getUserProfile("apple"));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.push(
          business_List?.length === 0
            ? `/signup-businessdetails?mode=facebook`
            : `/business-status`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const setAuthToken = (token) => {
  return (dispatch) => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else localStorage.removeItem("token");
  };
};

export const refreshToken = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };
};

export const loginWithShopify = ({ url, tokenResponse, userInfo, history }) => {
  return async (dispatch, getState) => {
    // let appCheckTokenResponse = await getToken(
    //   appCheck,
    //   /* forceRefresh= */ false
    // );
    dispatch({
      type: actionTypes.LOGIN_LOADING,
      payload: true,
    });
    axios
      .get(
        `${BASE_URL_WEBSITE}login/auth/shopify/callback?shop=${url}&source=oa-main-website&lang=en`,
        {
          headers: {
            // [`X-Firebase-AppCheck`]: appCheckTokenResponse.token,
            [`Access-Control-Allow-Origin`]: "*",
          },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        // dispatch(setAuthToken(data.data.access_token))
        dispatch({
          type: actionTypes.LOGIN_LOADING,
          payload: false,
        });
        window.open(
          // `https://api.stage.optimizeapp.com/api/login/auth/shopify/callback?shop=optimizeapptest.myshopify.com&source=oa-main-website&lang=en`,
          `${BASE_URL_WEBSITE}login/auth/shopify/callback?shop=${url}&source=oa-main-website&lang=en`,
          "_blank"
        );
        // window.open(
        //   `${BASE_URL_WEBSITE}login/auth/shopify/callback?shop=${url}&oa-main-website&lang=en`,
        //   "_blank"
        // );
        // window.open(
        //   `${baseURL}login/auth/shopify/callback?shop=${url}&source=source=oa-main-website`,
        //   '_self',
        // )
        // localStorage.setItem('token', data.data.access_token)
        // axios.defaults.headers.common['Authorization'] = `Bearer ${data.data.access_token}`
        // history('/campaignlist', { replace: true })
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.LOGIN_LOADING,
          payload: false,
        });
        window.open(
          // `https://api.stage.optimizeapp.com/api/login/auth/shopify/callback?shop=optimizeapptest.myshopify.com&source=oa-main-website&lang=en`,
          `${BASE_URL_WEBSITE}login/auth/shopify/callback?shop=${url}&source=oa-main-website&lang=en`,
          "_blank"
        );
        // errorMessageHandler(error)
      });
  };
};

export const shopifyLoginFlow = (accessToken, history) => {
  return async (dispatch, getState) => {
    localStorage.setItem("token", accessToken);
    // dispatch(checkTokenExpired(history));
    // axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`

    // history('/campaignlist', { replace: true })
  };
};

export const verifyOTPSignUp = ({ otp, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_OTP_LOADER,
      payload: true,
    });

    return axios
      .post(`${BASE_URL_WEBSITE}website/mobile/otp/verify`, { otp })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { otp },
        });
        callback && callback();
      })
      .catch((error) => errorMessageHandler(error));
  };
};

export const sendOTPBySMS = ({ mobile, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_OTP_LOADER,
      payload: true,
    });

    return axios
      .post(`${BASE_URL_WEBSITE}website/mobile/otp`, { mobile })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { mobile },
        });
        callback && callback();
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
      });
  };
};
export const sendOTPByCall = ({ mobile, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_OTP_LOADER,
      payload: true,
    });

    return axios
      .post(`${BASE_URL_WEBSITE}website/otp/call`, { mobile })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { mobile },
        });
        callback && callback();
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
      });
  };
};
export const sendOTPByWhatsApp = ({ mobile, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_OTP_LOADER,
      payload: true,
    });

    return axios
      .post(`${BASE_URL_WEBSITE}website/otp/whatsapp`, { mobile })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { mobile },
        });
        callback && callback();
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_OTP_LOADER,
          payload: false,
        });
      });
  };
};

export const getUserProfile = (platform) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_EMAIL_LOADER, payload: true });

    axios
      .post(`${BASE_URL_WEBSITE}website/me`)
      .then((res) => res.data)
      .then((data) => {
        dispatch({ type: actionTypes.SET_EMAIL_LOADER, payload: false });
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: { ...data.data, platform },
        });
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({ type: actionTypes.SET_EMAIL_LOADER, payload: false });
      });
  };
};

export const updateUser = ({ info, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_EMAIL_LOADER,
      payload: true,
    });
    axios
      .patch(`${BASE_URL_WEBSITE}website/update/user`, { ...info })
      .then((res) => res.data)
      .then((data) => {
        console.log("updateUser", data);
        // dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_USER_REGISTER_INFO,
          payload: data.data,
        });
      })
      .then(() => {
        return dispatch(
          businessRegister({
            info: {
              ...getState().optimizeregister.business,
              fbp: info.fbp,
              fbc: info.fbc,
              event_id: info.event_id,
              url: info.url,
              token: info.token,
            },
            history: () => {
              dispatch(
                sendOTPBySMS({ mobile: info.mobile, callback: callback })
              );
            },
          })
        );
      })
      .then((res) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
      })
      .catch((error) => {
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
      });
  };
};

/**
* 
* @param {*} info { 
* 
* "type":"SME",
*  "category":"Home Business",
 *   "name":"testBusines",
 *   "country_id":"9",
 *   "business_website":"https://optimizeapp.com",
 *  "lang":"en"
 *  // "token"==>for captcha
 * referral_code
} 
 * @returns 
 */
export const updateBusinessInfo = ({ info, callback }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_EMAIL_LOADER,
      payload: true,
    });
    return axios
      .patch(`${BASE_URL_WEBSITE}website/business/${info.business_id}`, {
        ...info,
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
        dispatch({
          type: actionTypes.SET_BUSINESS_REGISTER_INFO,
          payload: info,
        });
        callback && callback();
        // history.push(`/signup-userdetails`, { replace: true });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_EMAIL_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const checkBusinessVerified = (
  business_id,
  translate,
  updatingBusiness = false,
  accountVerified = false
) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.CHECK_BUSINESS_STATUS,
      payload: true,
    });
    return axios
      .get(`business/${business_id}/request_approval`)
      .then((res) => {
        return { success: res.data?.success, data: res.data.data };
      })
      .then(({ success, data }) => {
        let accountApproved =
          success && data.approval_status && data.approval_status === 1;

        dispatch();
        // updateBusinessConnectedToFacebook({
        //   approval_status: data.approval_status,
        // })

        // if (accountApproved) {
        //   NavigationService.navigate("Dashboard", {
        //     source: "start_verify",
        //     source_action: "a_check_status",
        //   });
        // }
        let approvedKey = data.approval_status;
        let message = null;
        let title = null;
        dispatch({
          type: actionTypes.SET_BUSINESS_DETAIL,
          payload: data.data,
        });
        // switch (approvedKey) {
        //   case 0:
        //     // message =
        //     //   "To give you the best service that we can offer, our team needs to verify your business first Please make sure the information you entered during registration is accurate before submitting If you need to make changes, you can do so in the menu under 'Business Info' and 'Personal Info'";
        //     break;
        //   case 1:
        //     title = "Your Business Is Now Verified!";
        //     message = "Get started and launch your ads now";
        //     !updatingBusiness &&
        //       toast({
        //         type: "success",
        //         message: translate[title],
        //         description: translate[message],
        //         duration: 10000,
        //       });
        //     break;
        //   case 2:
        //     title = "Request Submitted";
        //     message =
        //       getState().account.mainBusiness.rejection_reason_id &&
        //       getState().account.mainBusiness.rejection_reason_id === 5
        //         ? "Our team will review your information once more and will get back to you shortly"
        //         : "We'll be notifying you within 24 hours, so keep your eyes peeled for our app notification and email";
        //     (!updatingBusiness ||
        //       (getState().account.mainBusiness.rejection_reason_id &&
        //         getState().account.mainBusiness.rejection_reason_id === 5)) &&
        //       toast({
        //         type:
        //           getState().account.mainBusiness.rejection_reason_id &&
        //           getState().account.mainBusiness.rejection_reason_id === 5
        //             ? "success"
        //             : "info",
        //         message: translate[title],
        //         description: translate[message],
        //         duration: 10000,
        //       });
        //     if (
        //       getState().account.mainBusiness.rejection_reason_id &&
        //       getState().account.mainBusiness.rejection_reason_id === 5
        //     ) {
        //       dispatch({
        //         type: actionTypes.UPDATE_BUSINESS_INFO_SUCCESS,
        //         payload: {
        //           approval_status: 2,
        //         },
        //       });
        //     }
        //     break;
        //   case 3:
        //     dispatch();
        //     // updateBusinessConnectedToFacebook({
        //     //   rejection_reason:
        //     //     !!data.rejection_reason && data.rejection_reason,
        //     // })
        //     // message = "Your business could not be verified";
        //     break;
        //   default:
        //     title = undefined;
        //     message = "";
        //     break;
        // }
        // if (accountVerified) {
        //   dispatch(
        //     toggleAlertModal(
        //       {
        //         title: "Verifying your info",
        //         description: `Before you get started, we need to make sure your business is allowed to advertise on the different social media platforms This process usually takes 20-40 minutes`,
        //       },
        //       true
        //     )
        //   );
        // }
        return dispatch({
          type: actionTypes.CHECK_BUSINESS_STATUS,
          payload: false,
        });
      })
      .catch((error) => {
        // console.log("checkBusinessVerified Error", error);
        return dispatch({
          type: actionTypes.CHECK_BUSINESS_STATUS,
          payload: false,
        });
      });
  };
};
