import { createStore, applyMiddleware } from 'redux';

// Logger with default options
// import logger from 'redux-logger';
import thunk from 'redux-thunk';

import reducer from './reducers';

// const store = initialState => {
const store = createStore(reducer, applyMiddleware(thunk));
export default store;

// export default store;
