import actionsTypes from "../actionTypes";

const defaultState = {
  language: window.location.pathname.split("/")[1],
  // translations: {
  // 	en: {
  // 		locale: 'en',
  // 		messages: {},
  // 	},
  // 	ar: {
  // 		locale: 'ar',
  // 		messages: {},
  // 	},
  // },
  translations: {},
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 */
const languageReducer = (state = defaultState, action) => {
  // Override defaultState and get the language based on the url
  // var lang = window.location.pathname.split("/");
  // var validLang = ["ar", "en"];
  // if (lang[1] === "" || validLang.includes(lang[1]) < 1) lang[1] = "en";
  // state = {
  //   language: lang[1],
  //   translations: {},
  // };

  switch (action.type) {
    case actionsTypes.SET_LANGUAGE_LIST_POEDIT: {
      return {
        ...state,
        language: action.payload.language,
        translations: action.payload.translations,
      };
    }

    default:
      return state;
  }
};

export default languageReducer;
