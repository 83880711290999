import actionsTypes from "../actionTypes";

const defaultState = {
  loading: false,
  merchant_id: "3333",
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 */
const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionsTypes.SET_REGISTER_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default:
      return state;
  }
};

export default registerReducer;
