import axios from "axios";
import actionTypes, { BASE_URL_SALLA } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";
import { setAuthToken } from "./login.actions";

export const register = ({ email, password, merchant_id, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_REGISTER_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_SALLA}signup/salla`, {
        email,
        password,
        merchant_id,
      })
      .then((res) => res.data)
      .then((data) => {
        console.log("data", data);

        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_REGISTER_LOADER,
          payload: false,
        });
        history.replace(
          `/${getState().websiteLanguage.language}/sallabusinessselect?mid=${
            getState().login.mid
          }`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_REGISTER_LOADER,
          payload: false,
        });

        errorMessageHandler(error);
      });
  };
};
