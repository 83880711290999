import actionsTypes from "../actionTypes";

const defaultState = {
  sendOTPLoading: false,
  access_token: null,
  mobile: null,
  userInfo: null,
  verifyOTPLoading: false,
  savePasswordLoading: false,
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 */
const forgotPasswordReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionsTypes.SET_SEND_OTP_LOADER: {
      return {
        ...state,
        sendOTPLoading: action.payload,
      };
    }

    case actionsTypes.SET_OTP_BY_MOBILE: {
      return {
        ...state,
        access_token: action.payload.access_token,
        mobile: action.payload.mobile,
      };
    }

    case actionsTypes.SET_USER_INFO: {
      return {
        ...state,
        userInfo: action.payload,
      };
    }
    case actionsTypes.SET_VERIFY_OTP_LOADER: {
      return {
        ...state,
        verifyOTPLoading: action.payload,
      };
    }

    case actionsTypes.SAVE_UPDATE_PASS_LOADER: {
      return {
        ...state,
        savePasswordLoading: action.payload,
      };
    }
    default:
      return state;
  }
};

export default forgotPasswordReducer;
