import actionTypes from "../actionTypes";

const defaultState = {
  loading: false,
  user: {},
  business: {},
  business_website_available: false,
  business_name_available: false,
  businesswebsiteloading: false,
  mobileValidationLoader: false,
  mobile_number_available: false,
  loadingOTP: false,
};

const optimizeregisterReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionTypes.SET_EMAIL_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case actionTypes.LOGIN_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case actionTypes.SET_OTP_LOADER: {
      return {
        ...state,
        loadingOTP: action.payload,
      };
    }

    case actionTypes.SET_MOBILE_VALIDATION_LOADER: {
      return {
        ...state,
        mobileValidationLoader: action.payload,
      };
    }

    case actionTypes.SET_MOBILE_NUMBER_AVAILABLE: {
      return {
        ...state,
        mobile_number_available: action.payload,
      };
    }

    case actionTypes.SET_BUSINESS_NAME_AVAILABLE: {
      return {
        ...state,
        business_name_available: action.payload,
      };
    }

    case actionTypes.SET_BUSINESS_WEBSITE_AVAILABLE: {
      return {
        ...state,
        business_website_available: action.payload,
      };
    }

    case actionTypes.SET_BUSINESS_NAME_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case actionTypes.SET_BUSINESS_WEBSITE_LOADER: {
      return {
        ...state,
        businesswebsiteloading: action.payload,
      };
    }

    case actionTypes.SET_USER_REGISTER_INFO: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }

    case actionTypes.SET_BUSINESS_REGISTER_INFO: {
      return {
        ...state,
        business: {
          ...state.business,
          ...action.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default optimizeregisterReducer;
