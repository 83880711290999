import axios from "axios";
import actionTypes, { BASE_URL_SALLA } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";

export const login = ({ email, password, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_SALLA}login`, { email, password })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.replace(
          `/${getState().websiteLanguage.language}/sallabusinessselect?mid=${
            getState().login.mid
          }`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const setAuthToken = (token) => {
  return (dispatch) => {
    if (token) {
      localStorage.setItem("token", token);
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else localStorage.removeItem("token");
  };
};

export const refreshToken = () => {
  return (dispatch) => {
    const token = localStorage.getItem("token");
    if (token)
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };
};

export const logout = ({ history }) => {
  return (dispatch, getState) => {
    dispatch(setAuthToken(null));
    history.replace(`/en/salla?mid=${getState().login.mid}`);
  };
};

export const getMerchantId = ({ merchant_id }) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.GET_MERCHANT_DETAIL_LOADER, payload: true });
    axios
      .post(`${BASE_URL_SALLA}salla/merchant/details`, { merchant_id })
      .then((res) => res.data)
      .then((data) => {
        dispatch({
          type: actionTypes.SET_MERCHANT_INFO,
          payload: data.data.merchant_id,
        });
        dispatch({
          type: actionTypes.SET_MERCHANT_FULL_INFO,
          payload: data.data,
        });
        dispatch({
          type: actionTypes.GET_MERCHANT_DETAIL_LOADER,
          payload: false,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.GET_MERCHANT_DETAIL_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
        dispatch({
          type: actionTypes.SET_MERCHANT_INFO,
          payload: null,
        });
      });
  };
};

export const setMidFromURL = ({ mid }) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_LOCAL_MID,
      payload: mid,
    });
  };
};
/**
 *
 * @param {*} info is object of user aand response token having access_token
 * history navigation to redirect
 * @returns
 */
export const loginWithGoogle = ({ info, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_SALLA}login/auth/google/callback`, {
        ...info,
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.replace(
          `/${getState().websiteLanguage.language}/sallabusinessselect?mid=${
            getState().login.mid
          }`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const loginWithFacebook = ({ tokenResponse, userInfo, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_SALLA}login/auth/facebook/callback`, {
        ...tokenResponse,
        user: { ...userInfo },
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.replace(
          `/${getState().websiteLanguage.language}/sallabusinessselect?mid=${
            getState().login.mid
          }`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};

export const loginWithApple = ({ info, history }) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_LOGIN_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_SALLA}login/auth/apple/callback`, {
        ...info,
      })
      .then((res) => res.data)
      .then((data) => {
        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        history.replace(
          `/${getState().websiteLanguage.language}/sallabusinessselect?mid=${
            getState().login.mid
          }`,
          {
            replace: true,
          }
        );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_LOGIN_LOADER,
          payload: false,
        });
        errorMessageHandler(error);
      });
  };
};
