import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import store from "../store/store";
import { getLanguageListPOEdit } from "../store/actions";
import { connect } from "react-redux";
// import { useHistory, useLocation } from "react-router-dom";
//test
import loadable from "@loadable/component";
import OPLoader from "../components/optimize-others/OPLoader";

const OPCalendlyThankYou = loadable(
  () => import("../pages/OPCalendlyThankYou"),
  { fallback: <OPLoader /> }
);
const OPHome = loadable(() => import("../pages/OPHome"), {
  fallback: <OPLoader />,
});
const OPHomeCopy = loadable(() => import("../pages/OPHomeCopy"), {
  fallback: <OPLoader />,
});
const OPDemo = loadable(() => import("../pages/OPDemo"), {
  fallback: <OPLoader />,
});
const OPFeatures = loadable(() => import("../pages/OPFeatures"), {
  fallback: <OPLoader />,
});
const OPPlatforms = loadable(() => import("../pages/OPPlatforms"), {
  fallback: <OPLoader />,
});
const OPPlatformsSnapchat = loadable(
  () => import("../pages/OPPlatforms/OPPlatformsSnapchat"),
  {
    fallback: <OPLoader />,
  }
);
const OPPlatformsInstagram = loadable(
  () => import("../pages/OPPlatforms/OPPlatformsInstagram"),
  {
    fallback: <OPLoader />,
  }
);
const OPPlatformsTiktok = loadable(
  () => import("../pages/OPPlatforms/OPPlatformsTiktok"),
  {
    fallback: <OPLoader />,
  }
);
const OPPlatformsGoogle = loadable(
  () => import("../pages/OPPlatforms/OPPlatformsGoogle"),
  {
    fallback: <OPLoader />,
  }
);
const OPAcademy = loadable(() => import("../pages/OPAcademy"), {
  fallback: <OPLoader />,
});
// const OPPricing = loadable(() => import("../pages/OPPricing"), {
//   fallback: <OPLoader />,
// });
const OPPricingPlans = loadable(() => import("../pages/OPPricingPlans"), {
  fallback: <OPLoader />,
});
const Contact = loadable(() => import("../pages/OPContact"), {
  fallback: <OPLoader />,
});
const SallaSignin = loadable(() => import("../pages/OPSalla"), {
  fallback: <OPLoader />,
});
const SallaBusinessSelect = loadable(
  () => import("../pages/OPSallaBusinessSelect"),
  {
    fallback: <OPLoader />,
  }
);
const SallaAccountLinkSuccess = loadable(
  () => import("../pages/OPSallaAccountLinkSuccess"),
  {
    fallback: <OPLoader />,
  }
);
const SallaForgotPassword = loadable(
  () => import("../pages/OPSalla/PasswordReset/Email"),
  {
    fallback: <OPLoader />,
  }
);
const SallaForgotPasswordVerifyCode = loadable(
  () => import("../pages/OPSalla/PasswordReset/VerificationCode"),
  {
    fallback: <OPLoader />,
  }
);
const SallaCreateNewPassword = loadable(
  () => import("../pages/OPSalla/PasswordReset/CreateNewPassword"),
  {
    fallback: <OPLoader />,
  }
);
const SallaSignup = loadable(
  () => import("../pages/OPSalla/Signup/SallaSignup"),
  {
    fallback: <OPLoader />,
  }
);
const SallaSignUpSuccess = loadable(
  () => import("../pages/OPSalla/Signup/SallaSignupSuccess"),
  {
    fallback: <OPLoader />,
  }
);
const ZidSignin = loadable(() => import("../pages/OPZid"), {
  fallback: <OPLoader />,
});
const ZidBusinessSelect = loadable(
  () => import("../pages/OPZidBusinessSelect"),
  {
    fallback: <OPLoader />,
  }
);
const ZidAccountLinkSuccess = loadable(
  () => import("../pages/OPZidAccountLinkSuccess"),
  {
    fallback: <OPLoader />,
  }
);
const ZidForgotPassword = loadable(
  () => import("../pages/OPZid/PasswordReset/Email"),
  {
    fallback: <OPLoader />,
  }
);
const ZidForgotPasswordVerifyCode = loadable(
  () => import("../pages/OPZid/PasswordReset/VerificationCode"),
  {
    fallback: <OPLoader />,
  }
);
const ZidCreateNewPassword = loadable(
  () => import("../pages/OPZid/PasswordReset/CreateNewPassword"),
  {
    fallback: <OPLoader />,
  }
);
const ZidSignup = loadable(() => import("../pages/OPZid/Signup/SallaSignup"), {
  fallback: <OPLoader />,
});
const ZidSignUpSuccess = loadable(
  () => import("../pages/OPZid/Signup/SallaSignupSuccess"),
  {
    fallback: <OPLoader />,
  }
);
const OPInsights = loadable(() => import("../pages/OPInsights"), {
  fallback: <OPLoader />,
});
const OPInsightsKeepingItReal = loadable(
  () => import("../pages/OPInsights/OPInsightsKeepingItReal"),
  { fallback: <OPLoader /> }
);
const OPInsightsTemplate = loadable(
  () => import("../pages/OPInsights/OPBlogTemplate"),
  { fallback: <OPLoader /> }
);
const OPInsightsRamadan = loadable(
  () => import("../pages/OPInsights/OPInsightsRamadan"),
  { fallback: <OPLoader /> }
);
const OPInsightsRamadanMessaging = loadable(
  () => import("../pages/OPInsights/OPInsightsRamadanMessaging"),
  { fallback: <OPLoader /> }
);
const OPInsightsRamadanAudience = loadable(
  () => import("../pages/OPInsights/OPInsightsRamadanAudience"),
  { fallback: <OPLoader /> }
);
const OPInsightsRamadanContent = loadable(
  () => import("../pages/OPInsights/OPInsightsRamadanContent"),
  { fallback: <OPLoader /> }
);
const OPInsightsWhyAdvertise = loadable(
  () => import("../pages/OPInsights/OPInsightsWhyAdvertise"),
  { fallback: <OPLoader /> }
);
const OPInsightsMarketingCalendar = loadable(
  () => import("../pages/OPInsights/OPInsightsMarketingCalendar"),
  { fallback: <OPLoader /> }
);
const OPInsightsHarnessingPower = loadable(
  () => import("../pages/OPInsights/OPInsightsHarnessingPower"),
  { fallback: <OPLoader /> }
);
const OPInsightsFirstCampaign = loadable(
  () => import("../pages/OPInsights/OPInsightsFirstCampaign"),
  { fallback: <OPLoader /> }
);
const OPInsightsPlatforms = loadable(
  () => import("../pages/OPInsights/OPInsightsPlatforms"),
  { fallback: <OPLoader /> }
);
const OPInsightsCampaignObjectives = loadable(
  () => import("../pages/OPInsights/OPInsightsCampaignObjectives"),
  { fallback: <OPLoader /> }
);
const OPInsightsCampaignDuration = loadable(
  () => import("../pages/OPInsights/OPInsightsCampaignDuration"),
  { fallback: <OPLoader /> }
);
const OPInsightsTargeting = loadable(
  () => import("../pages/OPInsights/OPInsightsTargeting"),
  { fallback: <OPLoader /> }
);
const OPInsightsBudget = loadable(
  () => import("../pages/OPInsights/OPInsightsBudget"),
  { fallback: <OPLoader /> }
);

const OPPDF = loadable(() => import("../pages/OPPDF/OPPDF"), {
  fallback: <OPLoader />,
});
const OPPDFLaunchYourAd = loadable(
  () => import("../pages/OPPDF/OPPDFLaunchYourAd"),
  { fallback: <OPLoader /> }
);
const OPPDFQuestionsAboutOptimize = loadable(
  () => import("../pages/OPPDF/OPPDFQuestionsAboutOptimize"),
  { fallback: <OPLoader /> }
);
const OPPDFSevenSecrets = loadable(
  () => import("../pages/OPPDF/OPPDFSevenSecrets"),
  { fallback: <OPLoader /> }
);
const OPPDFEmailMarketing = loadable(
  () => import("../pages/OPPDF/OPPDFEmailMarketing"),
  { fallback: <OPLoader /> }
);
const OPPDFTiktok = loadable(() => import("../pages/OPPDF/OPPDFTiktok"), {
  fallback: <OPLoader />,
});
const OPPDFLaunchYourAdsOnTiktok = loadable(
  () => import("../pages/OPPDF/OPPDFLaunchYourAdsOnTiktok"),
  { fallback: <OPLoader /> }
);
const Download = loadable(() => import("../pages/Download"), {
  fallback: <OPLoader />,
});
const TermsAndConditions = loadable(
  () => import("../pages/OPTermsAndConditions"),
  { fallback: <OPLoader /> }
);
const PrivacyPolicy = loadable(() => import("../pages/OPPrivacyPolicy"), {
  fallback: <OPLoader />,
});
const PrivacyPolicyDynamic = loadable(
  () => import("../pages/OPPrivacyPolicyDynamic"),
  {
    fallback: <OPLoader />,
  }
);
const OPRefund = loadable(() => import("../pages/OPRefund"), {
  fallback: <OPLoader />,
});
const OPDesignRequirements = loadable(
  () => import("../pages/OPSurvey/OPDesignRequirements"),
  { fallback: <OPLoader /> }
);
const OPDesignRequirementsFree = loadable(
  () => import("../pages/OPSurvey/OPDesignRequirementsFree"),
  { fallback: <OPLoader /> }
);
const OPFormThankYou = loadable(
  () => import("../pages/OPSurvey/OPFormThankYou"),
  { fallback: <OPLoader /> }
);
const OPAnalyticsRequest = loadable(
  () => import("../pages/OPSurvey/OPAnalyticsRequest"),
  { fallback: <OPLoader /> }
);
const OPAnalyticsThankYou = loadable(
  () => import("../pages/OPSurvey/OPAnalyticsThankYou"),
  { fallback: <OPLoader /> }
);
const OPServicesForm = loadable(
  () => import("../pages/OPSurvey/OPServicesForm"),
  { fallback: <OPLoader /> }
);
const OPServicesFormThankYou = loadable(
  () => import("../pages/OPSurvey/OPServicesFormThankYou"),
  { fallback: <OPLoader /> }
);
const OPPoliticalForm = loadable(
  () => import("../pages/OPSurvey/OPPoliticalForm"),
  { fallback: <OPLoader /> }
);
const OPPoliticalFormThankYou = loadable(
  () => import("../pages/OPSurvey/OPPoliticalFormThankYou"),
  { fallback: <OPLoader /> }
);
const NotFoundScreen = loadable(
  () => import("../components/others/NotFoundScreen"),
  { fallback: <OPLoader /> }
);

const OPQuizFunnel = loadable(
  () => import("../pages/OPSalesPages/OPQuizFunnel"),
  { fallback: <OPLoader /> }
);
// const OPEvergreenWebinar = loadable(
//   () => import("../pages/OPSalesPages/OPEvergreenWebinar"),
//   { fallback: <OPLoader /> }
// );
const OPOnboarding = loadable(
  () => import("../pages/OPSalesPages/OPOnboarding"),
  { fallback: <OPLoader /> }
);
const OPOnboardingReplay = loadable(
  () => import("../pages/OPSalesPages/OPOnboardingReplay"),
  { fallback: <OPLoader /> }
);
// const OP7Secrets = loadable(() => import("../pages/OPSalesPages/OP7Secrets"), {
//   fallback: <OPLoader />,
// });
// const OP7SecretsThankYou = loadable(
//   () => import("../pages/OPSalesPages/OP7SecretsThankYou"),
//   { fallback: <OPLoader /> }
// );
// const OPValueFunnel = loadable(
//   () => import("../pages/OPSalesPages/OPValueFunnel"),
//   { fallback: <OPLoader /> }
// );
// const OPValueThankYou = loadable(
//   () => import("../pages/OPSalesPages/OPValueThankYou"),
//   { fallback: <OPLoader /> }
// );
const OPRetentionReplay = loadable(
  () => import("../pages/OPSalesPages/OPRetentionReplay"),
  { fallback: <OPLoader /> }
);
// const OPFandBWebinar = loadable(
//   () => import("../pages/OPSalesPages/OPFandBWebinar"),
//   { fallback: <OPLoader /> }
// );
// const OPBlackFriday = loadable(
//   () => import("../pages/OPSalesPages/OPBlackFriday"),
//   { fallback: <OPLoader /> }
// );
// const OPBlackFridayThankYou = loadable(
//   () => import("../pages/OPSalesPages/OPBlackFridayThankYou"),
//   { fallback: <OPLoader /> }
// );
const OPTycoonCourse = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCourse"),
  { fallback: <OPLoader /> }
);
const OPTycoonCourseEidAdha = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCourseEidAdha"),
  { fallback: <OPLoader /> }
);
const OPTycoonCourse250 = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCourse250"),
  { fallback: <OPLoader /> }
);
const OPTycoonCourse150 = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCourse150"),
  { fallback: <OPLoader /> }
);
const OPTycoonCoursePresell = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCoursePresell"),
  { fallback: <OPLoader /> }
);
const OPTycoonCourseThankYou = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCourseThankYou"),
  { fallback: <OPLoader /> }
);
const OPTycoonCoursePaymentFailure = loadable(
  () => import("../pages/OPSalesPages/OPTycoonCoursePaymentFailure"),
  { fallback: <OPLoader /> }
);
const OPTycoonLanding = loadable(
  () => import("../pages/OPSalesPages/OPTycoonLanding"),
  { fallback: <OPLoader /> }
);
const OPTycoonVideo = loadable(
  () => import("../pages/OPSalesPages/OPTycoonVideo"),
  { fallback: <OPLoader /> }
);
const OPTycoonVideoSegments = loadable(
  () => import("../pages/OPSalesPages/OPTycoonVideoSegments"),
  { fallback: <OPLoader /> }
);
const OPLeadFunnel = loadable(
  () => import("../pages/OPSalesPages/OPLeadFunnel"),
  { fallback: <OPLoader /> }
);
const OPFollowUp = loadable(() => import("../pages/OPSurvey/OPFollowUp"), {
  fallback: <OPLoader />,
});
const OPFollowUpThankYou = loadable(
  () => import("../pages/OPSurvey/OPFollowUpThankYou"),
  { fallback: <OPLoader /> }
);
// const OPOptimizeAcademy = loadable(
//   () => import("../pages/OPSalesPages/OPOptimizeAcademy"),
//   { fallback: <OPLoader /> }
// );
const OPMarketingCourse = loadable(
  () => import("../pages/OPSalesPages/OPMarketingCourse"),
  { fallback: <OPLoader /> }
);
const OPSignInHome = loadable(() => import("../pages/OPSignUp/SignInHome"), {
  fallback: <OPLoader />,
});
const OPSignInHomeMobile = loadable(
  () => import("../pages/OPSignUp/SignInWithMobile"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignInForgotPassword = loadable(
  () => import("../pages/OPSignUp/ForgotPassword"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignUpHome = loadable(() => import("../pages/OPSignUp/SignUpHome"), {
  fallback: <OPLoader />,
});
const OPSignUpBusinessDetails = loadable(
  () => import("../pages/OPSignUp/SignUpBusinessDetails"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignUpReferal = loadable(
  () => import("../pages/OPSignUp/SignUpReferal"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignUpPersonalDetails = loadable(
  () => import("../pages/OPSignUp/SignUpPersonalDetails"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignUpCreatePassword = loadable(
  () => import("../pages/OPSignUp/SignUpCreatePassword"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignUpVerifyNumber = loadable(
  () => import("../pages/OPSignUp/SignUpVerifyNumber"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignInVerifyNumber = loadable(
  () => import("../pages/OPSignUp/SignInVerifyNumber"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignUpThankYou = loadable(
  () => import("../pages/OPSignUp/SignUpThankYou"),
  {
    fallback: <OPLoader />,
  }
);
const OPSignInDetail = loadable(
  () => import("../pages/OPSignUp/SignInDetail"),
  {
    fallback: <OPLoader />,
  }
);
// const OPTycoonCourseSND = loadable(
//   () => import("../pages/OPSalesPages/OPTycoonCourseSND"),
//   { fallback: <OPLoader /> }
// );
// const OPTycoonCourseBTS = loadable(
//   () => import("../pages/OPSalesPages/OPTycoonCourseBTS"),
//   { fallback: <OPLoader /> }
// );

class Routes extends Component {
  state = { mid: new URLSearchParams(window.location.search).get("mid") };
  async componentDidMount() {
    const queryParamsmid = new URLSearchParams(window.location.search).get(
      "mid"
    );
    console.log("queryParams", queryParamsmid);

    var lang = window.location.pathname.split("/");
    let language = lang[1];
    var validLang = ["ar", "en"];
    // var validLang = ["en"];
    if (language === "" || validLang.includes(language) < 1) {
      language =
        lang[2]?.includes("signup") ||
        lang[1]?.includes("signup") ||
        language.includes("signin") ||
        lang[2]?.includes("signin") ||
        lang[1]?.includes("zid") ||
        language.includes("zid") ||
        lang[2]?.includes("salla") ||
        lang[1]?.includes("salla") ||
        language.includes("salla")
          ? "ar"
          : "en";
      // language = "ar";
    }
    await store.dispatch(getLanguageListPOEdit(language));
    if (queryParamsmid?.length >= 1) {
      // await store.dispatch(setMidFromURL({ mid: queryParamsmid }));
      this.setState({
        mid: queryParamsmid,
      });
    }

    document.documentElement.lang = language;
  }

  render() {
    const { language } = this.props;
    // const language = 'en';
    return (
      <>
        <div id="app" dir={language === "ar" ? "rtl" : "ltr"}>
          <Router>
            <Switch>
              <Route exact path={"/" + language} component={OPHome} />
              <Route exact path={"/"}>
                <Redirect to={"/" + language} />
              </Route>
              <Route exact path={"/(ar|en)"}>
                <Redirect to={"/" + language} />
              </Route>
              <Route
                exact
                path={"/" + language + "/home-copy"}
                component={OPHomeCopy}
              />
              <Route exact path="*/home-copy">
                <Redirect to={"/" + language + "/home-copy"} />
              </Route>
              <Route exact path={"/" + language + "/demo"} component={OPDemo} />
              <Route exact path="*/demo">
                <Redirect to={"/" + language + "/demo"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/features"}
                component={OPFeatures}
              />
              <Route exact path="*/features">
                <Redirect to={"/" + language + "/features"} />
              </Route>

              <Route
                exact
                path={"/" + language + "/signinforgotpassword"}
                component={OPSignInForgotPassword}
              />
              <Route exact path="*/signinforgotpassword">
                <Redirect to={"/" + language + "/signinforgotpassword"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signinmobile"}
                component={OPSignInHomeMobile}
              />
              <Route exact path="*/signinmobile">
                <Redirect to={"/" + language + "/signinmobile"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signin"}
                component={OPSignInHome}
              />
              <Route exact path="*/signin">
                <Redirect to={"/" + language + "/signin"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup"}
                component={OPSignUpHome}
              />
              <Route exact path="*/signup">
                <Redirect to={"/" + language + "/signup"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup-businessdetails"}
                component={OPSignUpBusinessDetails}
              />
              <Route exact path="*/signup-businessdetails">
                <Redirect to={"/" + language + "/signup-businessdetails"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup-referral"}
                component={OPSignUpReferal}
              />
              <Route exact path="*/signup-referral">
                <Redirect to={"/" + language + "/signup-referral"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup-personaldetails"}
                component={OPSignUpPersonalDetails}
              />
              <Route exact path="*/signup-personaldetails">
                <Redirect to={"/" + language + "/signup-personaldetails"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup-createpassword"}
                component={OPSignUpCreatePassword}
              />
              <Route exact path="*/signup-createpassword">
                <Redirect to={"/" + language + "/signup-createpassword"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup-verifyphone"}
                component={OPSignUpVerifyNumber}
              />
              <Route exact path="*/signup-verifyphone">
                <Redirect to={"/" + language + "/signup-verifyphone"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signin-verifyphone"}
                component={OPSignInVerifyNumber}
              />
              <Route exact path="*/signin-verifyphone">
                <Redirect to={"/" + language + "/signin-verifyphone"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/signup-thankyou"}
                component={OPSignUpThankYou}
              />
              <Route exact path="*/signup-thankyou">
                <Redirect to={"/" + language + "/signup-thankyou"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/business-status"}
                component={OPSignInDetail}
              />
              <Route exact path="*/business-status">
                <Redirect to={"/" + language + "/business-status"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/platforms"}
                component={OPPlatforms}
              />
              <Route exact path="*/platforms">
                <Redirect to={"/" + language + "/platforms"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/platforms/snapchat"}
                component={OPPlatformsSnapchat}
              />
              <Route exact path="*/platforms/snapchat">
                <Redirect to={"/" + language + "/platforms/snapchat"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/platforms/instagram"}
                component={OPPlatformsInstagram}
              />
              <Route exact path="*/platforms/instagram">
                <Redirect to={"/" + language + "/platforms/instagram"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/platforms/tiktok"}
                component={OPPlatformsTiktok}
              />
              <Route exact path="*/platforms/tiktok">
                <Redirect to={"/" + language + "/platforms/tiktok"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/platforms/google"}
                component={OPPlatformsGoogle}
              />
              <Route exact path="*/platforms/google">
                <Redirect to={"/" + language + "/platforms/google"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/academy"}
                component={OPAcademy}
              />
              <Route exact path="*/academy">
                <Redirect to={"/" + language + "/academy"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/contact"}
                component={Contact}
              />
              <Route exact path="*/contact">
                <Redirect to={"/" + language + "/contact"} />
              </Route>
              <Route
                path={"/" + language + `/salla`}
                component={() => <SallaSignin />}
              />
              <Route exact path={`*/salla`}>
                <Redirect
                  to={"/" + language + `/salla?mid=${this.state.mid}`}
                />
              </Route>
              <Route
                path={"/" + language + "/sallabusinessselect"}
                component={SallaBusinessSelect}
              />
              <Route exact path="*/sallabusinessselect">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/sallabusinessselect?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/sallaaaccountlinksuccess"}
                component={SallaAccountLinkSuccess}
              />
              <Route exact path="*/sallaaaccountlinksuccess">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/sallaaaccountlinksuccess?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/sallaforgotpassword"}
                component={SallaForgotPassword}
              />
              <Route exact path="*/sallaforgotpassword">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/sallaforgotpassword?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/sallaforgotpasswordverifycode"}
                component={SallaForgotPasswordVerifyCode}
              />
              <Route exact path="*/sallaforgotpasswordverifycode">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/sallaforgotpasswordverifycode?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/sallacreatenewpaassword"}
                component={SallaCreateNewPassword}
              />
              <Route exact path="*/sallacreatenewpaassword">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/sallacreatenewpaassword?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/sallasignup"}
                component={SallaSignup}
              />
              <Route exact path="*/sallasignup">
                <Redirect
                  to={"/" + language + `/sallasignup?mid=${this.state.mid}`}
                />
              </Route>
              <Route
                path={"/" + language + "/sallasignupsuccess"}
                component={SallaSignUpSuccess}
              />
              <Route exact path="*/sallasignupsuccess">
                <Redirect
                  to={
                    "/" + language + `/sallasignupsuccess?mid=${this.state.mid}`
                  }
                />
              </Route>
              {/* Zidd*/}
              <Route
                path={"/" + language + `/zid`}
                component={() => <ZidSignin />}
              />
              <Route exact path={`*/zid`}>
                <Redirect to={"/" + language + `/zid?mid=${this.state.mid}`} />
              </Route>
              <Route
                path={"/" + language + "/zidbusinessselect"}
                component={ZidBusinessSelect}
              />
              <Route exact path="*/zidbusinessselect">
                <Redirect
                  to={
                    "/" + language + `/zidbusinessselect?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/zidaccountlinksuccess"}
                component={ZidAccountLinkSuccess}
              />
              <Route exact path="*/zidaccountlinksuccess">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/zidaccountlinksuccess?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/zidforgotpassword"}
                component={ZidForgotPassword}
              />
              <Route exact path="*/zidforgotpassword">
                <Redirect
                  to={
                    "/" + language + `/zidforgotpassword?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/zidforgotpasswordverifycode"}
                component={ZidForgotPasswordVerifyCode}
              />
              <Route exact path="*/zidforgotpasswordverifycode">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/zidforgotpasswordverifycode?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/zidcreatenewpaassword"}
                component={ZidCreateNewPassword}
              />
              <Route exact path="*/zidcreatenewpaassword">
                <Redirect
                  to={
                    "/" +
                    language +
                    `/zidcreatenewpaassword?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                path={"/" + language + "/zidsignup"}
                component={ZidSignup}
              />
              <Route exact path="*/zidsignup">
                <Redirect
                  to={"/" + language + `/zidsignup?mid=${this.state.mid}`}
                />
              </Route>
              <Route
                path={"/" + language + "/zidsignupsuccess"}
                component={ZidSignUpSuccess}
              />
              <Route exact path="*/zidsignupsuccess">
                <Redirect
                  to={
                    "/" + language + `/zidsignupsuccess?mid=${this.state.mid}`
                  }
                />
              </Route>
              <Route
                exact
                path={"/" + language + "/pricing"}
                component={OPPricingPlans}
              />
              <Route exact path="*/pricing">
                <Redirect to={"/" + language + "/pricing"} />
              </Route>
              {/* PDF */}
              <Route exact path={"/" + language + "/pdf"} component={OPPDF} />
              <Route exact path="*/pdf">
                <Redirect to={"/" + language + "/pdf/launch-your-ad"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/pdf/launch-your-ad"}
                component={OPPDFLaunchYourAd}
              />
              <Route exact path="*/pdf/launch-your-ad">
                <Redirect to={"/" + language + "/pdf/launch-your-ad"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/pdf/questions-about-optimize"}
                component={OPPDFQuestionsAboutOptimize}
              />
              <Route exact path="*/pdf">
                <Redirect
                  to={"/" + language + "/pdf/questions-about-optimize"}
                />
              </Route>
              <Route
                exact
                path={"/" + language + "/pdf/seven-secrets"}
                component={OPPDFSevenSecrets}
              />
              <Route exact path="*/pdf/seven-secrets">
                <Redirect to={"/" + language + "/pdf/seven-secrets"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/pdf/email-marketing"}
                component={OPPDFEmailMarketing}
              />
              <Route exact path="*/pdf/email-marketing">
                <Redirect to={"/" + language + "/pdf/email-marketing"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/pdf/tiktok-ads"}
                component={OPPDFTiktok}
              />
              <Route exact path="*/pdf/tiktok-ads">
                <Redirect to={"/" + language + "/pdf/tiktok-ads"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/pdf/launch-your-ads-on-tiktok"}
                component={OPPDFLaunchYourAdsOnTiktok}
              />
              <Route exact path="*/pdf/how-to-launch-your-ads-on-tiktok">
                <Redirect
                  to={"/" + language + "/pdf/launch-your-ads-on-tiktok"}
                />
              </Route>
              {/* End of PDF */}
              {/* Survey */}
              <Route
                exact
                path={"/" + language + "/creativeform"}
                component={OPDesignRequirements}
              />
              <Route exact path="*/creativeform">
                <Redirect to={"/" + language + "/creativeform"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/creativeformfree"}
                component={OPDesignRequirementsFree}
              />
              <Route exact path="*/creativeformfree">
                <Redirect to={"/" + language + "/creativeformfree"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/followup"}
                component={OPFollowUp}
              />
              <Route exact path="*/followup">
                <Redirect to={"/" + language + "/followup"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/followup-thankyou"}
                component={OPFollowUpThankYou}
              />
              <Route exact path="*/followup-thankyou">
                <Redirect to={"/" + language + "/followup-thankyou"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/form-thankyou"}
                component={OPFormThankYou}
              />
              <Route exact path="*/form-thankyou">
                <Redirect to={"/" + language + "/form-thankyou"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/calendly-thankyou"}
                component={OPCalendlyThankYou}
              />
              <Route exact path="*/calendly-thankyou">
                <Redirect to={"/" + language + "/calendly-thankyou"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/analytics-request"}
                component={OPAnalyticsRequest}
              />
              <Route exact path="*/analytics-request">
                <Redirect to={"/" + language + "/analytics-request"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/analytics-thankyou"}
                component={OPAnalyticsThankYou}
              />
              <Route exact path="*/analytics-thankyou">
                <Redirect to={"/" + language + "/analytics-thankyou"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/medical-form"}
                component={OPServicesForm}
              />
              <Route exact path="*/medical-form">
                <Redirect to={"/" + language + "/medical-form"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/medical-form-thankyou"}
                component={OPServicesFormThankYou}
              />
              <Route exact path="*/medical-form-thankyou">
                <Redirect to={"/" + language + "/medical-form-thankyou"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/political-advocacy-form"}
                component={OPPoliticalForm}
              />
              <Route exact path="*/political-advocacy-form">
                <Redirect to={"/" + language + "/political-advocacy-form"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/political-advocacy-form-thankyou"}
                component={OPPoliticalFormThankYou}
              />
              <Route exact path="*/political-advocacy-form-thankyou">
                <Redirect
                  to={"/" + language + "/political-advocacy-form-thankyou"}
                />
              </Route>
              {/* End of Survey */}
              {/* Sales Page */}
              <Route exact path={"/ar/onboarding"} component={OPOnboarding} />
              <Route exact path="*/onboarding">
                <Redirect to={"/ar/onboarding"} />
              </Route>
              <Route
                exact
                path={"/ar/onboarding-replay"}
                component={OPOnboardingReplay}
              />
              <Route exact path="*/onboarding-replay">
                <Redirect to={"/ar/onboarding-replay"} />
              </Route>
              <Route
                exact
                path={"/ar/retention-replay"}
                component={OPRetentionReplay}
              />
              <Route exact path="*/retention-replay">
                <Redirect to={"/ar/retention-replay"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course-presell"}
                component={OPTycoonCoursePresell}
              />
              <Route exact path="*/academy/courses/tycoon-course-presell">
                <Redirect to={"/ar/academy/courses/tycoon-course-presell"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course"}
                component={OPTycoonCourse}
              />
              <Route exact path="*/academy/courses/tycoon-course">
                <Redirect to={"/ar/academy/courses/tycoon-course"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course-eid-adha"}
                component={OPTycoonCourseEidAdha}
              />
              <Route exact path="*/academy/courses/tycoon-course-eid-adha">
                <Redirect to={"/ar/academy/courses/tycoon-course-eid-adha"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course-250"}
                component={OPTycoonCourse250}
              />
              <Route exact path="*/academy/courses/tycoon-course-250">
                <Redirect to={"/ar/academy/courses/tycoon-course-250"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course-150"}
                component={OPTycoonCourse150}
              />
              <Route exact path="*/academy/courses/tycoon-course-150">
                <Redirect to={"/ar/academy/courses/tycoon-course-150"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course-thank-you"}
                component={OPTycoonCourseThankYou}
              />
              <Route exact path="*/academy/courses/tycoon-course-thank-you">
                <Redirect to={"/ar/academy/courses/tycoon-course-thank-you"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-course-payment-failure"}
                component={OPTycoonCoursePaymentFailure}
              />
              <Route
                exact
                path="*/academy/courses/tycoon-course-payment-failure"
              >
                <Redirect
                  to={"/ar/academy/courses/tycoon-course-payment-failure"}
                />
              </Route>
              <Route exact path={"/ar/quiz"} component={OPQuizFunnel} />
              <Route exact path="*/quiz">
                <Redirect to={"/ar/quiz"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-landing"}
                component={OPTycoonLanding}
              />
              <Route exact path="*/academy/courses/tycoon-landing">
                <Redirect to={"/ar/academy/courses/tycoon-landing"} />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-video"}
                component={OPTycoonVideo}
              />
              <Route exact path="*/academy/courses/tycoon-video">
                <Redirect
                  to={{
                    pathname: "/ar/academy/courses/tycoon-video",
                    search: `?utm_source=tycoon-landing&utm_medium=lead-form`,
                  }}
                />
              </Route>
              <Route
                exact
                path={"/ar/academy/courses/tycoon-video-segments"}
                component={OPTycoonVideoSegments}
              />
              <Route exact path="*/academy/courses/tycoon-video-segments">
                <Redirect
                  to={{
                    pathname: "/ar/academy/courses/tycoon-video-segments",
                    search: `?utm_source=tycoon-landing&utm_medium=lead-form`,
                  }}
                />
              </Route>
              <Route exact path={"/ar/lead-funnel"} component={OPLeadFunnel} />
              <Route exact path="*/lead-funnel">
                <Redirect to={"/ar/lead-funnel"} />
              </Route>
              {/*<Route
                exact
                path={"/ar/academy/courses/optimize-academy"}
                component={OPOptimizeAcademy}
              />*/}
              <Route
                exact
                path={"/ar/academy/courses/marketing-course"}
                component={OPMarketingCourse}
              />
              <Route exact path="*/academy/courses/marketing-course">
                <Redirect to={"/ar/academy/courses/marketing-course"} />
              </Route>
              {/* End of Sales Page */}
              {/* Start of Blogs Change en bak to language late when arabic is ready */}
              <Route exact path={"/en/insights"} component={OPInsights} />
              <Route exact path="*/insights">
                <Redirect to={"/en/insights"} />
              </Route>
              <Route
                exact
                path={"/en/insights/keeping-it-real"}
                component={OPInsightsKeepingItReal}
              />
              <Route exact path="*/insights/keeping-it-real">
                <Redirect to={"/en/insights/keeping-it-real"} />
              </Route>
              <Route
                exact
                path={"/en/insights/ramadan-2023"}
                component={OPInsightsRamadan}
              />
              <Route exact path="*/insights/ramadan-2023">
                <Redirect to={"/en/insights/ramadan-2023"} />
              </Route>
              <Route
                exact
                path={"/en/insights/ramadan-messaging"}
                component={OPInsightsRamadanMessaging}
              />
              <Route exact path="*/insights/ramadan-messaging">
                <Redirect to={"/en/insights/ramadan-messaging"} />
              </Route>
              <Route
                exact
                path={"/en/insights/ramadan-audience"}
                component={OPInsightsRamadanAudience}
              />
              <Route exact path="*/insights/ramadan-audience">
                <Redirect to={"/en/insights/ramadan-audience"} />
              </Route>
              <Route
                exact
                path={"/en/insights/ramadan-content"}
                component={OPInsightsRamadanContent}
              />
              <Route exact path="*/insights/ramadan-content">
                <Redirect to={"/en/insights/ramadan-content"} />
              </Route>
              <Route
                exact
                path={"/en/insights/why-advertise-on-social-media"}
                component={OPInsightsWhyAdvertise}
              />
              <Route exact path="*/insights/why-advertise-on-social-media">
                <Redirect to={"/en/insights/why-advertise-on-social-media"} />
              </Route>
              <Route
                exact
                path={"/en/insights/creating-a-social-media-marketing-calendar"}
                component={OPInsightsMarketingCalendar}
              />
              <Route
                exact
                path="*/insights/creating-a-social-media-marketing-calendar"
              >
                <Redirect
                  to={"/en/insights/creating-a-social-media-marketing-calendar"}
                />
              </Route>
              <Route
                exact
                path={"/en/insights/harnessing-the-complementary-power"}
                component={OPInsightsHarnessingPower}
              />
              <Route exact path="*/insights/harnessing-the-complementary-power">
                <Redirect
                  to={"/en/insights/harnessing-the-complementary-power"}
                />
              </Route>
              <Route
                exact
                path={"/en/insights/putting-together-your-first-campaign"}
                component={OPInsightsFirstCampaign}
              />
              <Route
                exact
                path="*/insights/putting-together-your-first-campaign"
              >
                <Redirect
                  to={"/en/insights/putting-together-your-first-campaign"}
                />
              </Route>
              <Route
                exact
                path={"/en/insights/template"}
                component={OPInsightsTemplate}
              />
              <Route exact path="*/insights/template">
                <Redirect to={"/en/insights/template"} />
              </Route>
              <Route
                exact
                path={"/ar/insights/supported-platforms"}
                component={OPInsightsPlatforms}
              />
              <Route exact path="*/insights/supported-platforms">
                <Redirect to={"/ar/insights/supported-platforms"} />
              </Route>
              <Route
                exact
                path={"/ar/insights/campaign-objectives"}
                component={OPInsightsCampaignObjectives}
              />
              <Route exact path="*/insights/campaign-objectives">
                <Redirect to={"/ar/insights/campaign-objectives"} />
              </Route>
              <Route
                exact
                path={"/ar/insights/campaign-duration"}
                component={OPInsightsCampaignDuration}
              />
              <Route exact path="*/insights/campaign-duration">
                <Redirect to={"/ar/insights/campaign-duration"} />
              </Route>
              <Route
                exact
                path={"/ar/insights/targeting"}
                component={OPInsightsTargeting}
              />
              <Route exact path="*/insights/targeting">
                <Redirect to={"/ar/insights/targeting"} />
              </Route>
              <Route
                exact
                path={"/ar/insights/budget"}
                component={OPInsightsBudget}
              />
              <Route exact path="*/insights/budget">
                <Redirect to={"/ar/insights/budget"} />
              </Route>
              {/* End of Blogs */}
              <Route
                exact
                path={"/" + language + "/terms-and-conditions"}
                component={TermsAndConditions}
              />
              <Route exact path="*/terms-and-conditions">
                <Redirect to={"/" + language + "/terms-and-conditions"} />
              </Route>
              <Route exact path="*/terms_conditions">
                <Redirect to={"/" + language + "/terms-and-conditions"} />
              </Route>
              <Route exact path="/download" component={Download}></Route>
              <Route
                exact
                path={"/" + language + "/privacy-policy"}
                component={PrivacyPolicy}
              />
              <Route exact path="*/privacy">
                <Redirect to={"/" + language + "/privacy-policy"} />
              </Route>
              <Route exact path="*/privacy-policy">
                <Redirect to={"/" + language + "/privacy-policy"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/dyn-privacy-policy/"}
                component={PrivacyPolicyDynamic}
              />
              <Route exact path={"*/dyn-privacy-policy/"}>
                <Redirect to={"/" + language + "/dyn-privacy-policy/"} />
              </Route>
              <Route exact path={"*/dyn-privacy-policy/"}>
                <Redirect to={"/" + language + "/dyn-privacy-policy/"} />
              </Route>
              <Route
                exact
                path={"/" + language + "/refund"}
                component={OPRefund}
              />
              <Route exact path="*/refund">
                <Redirect to={"/" + language + "/refund"} />
              </Route>
              <Route component={NotFoundScreen} />
            </Switch>
          </Router>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  translate: state.websiteLanguage.translations,
  language: state.websiteLanguage.language,
  business_name: state.business.business_name,
});
export default connect(mapStateToProps)(Routes);
