import axios from "axios";
import actionTypes, { BASE_URL_ZID } from "../actionTypes";
import { errorMessageHandler } from "./errorhandler.actions";
import { setAuthToken } from "./login.actions";

export const register = ({
  email,
  password,
  merchant_id,
  history,
  business_category,
}) => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.SET_REGISTER_LOADER,
      payload: true,
    });
    axios
      .post(`${BASE_URL_ZID}signup/zid`, {
        email,
        password,
        merchant_id,
        business_category,
      })
      .then((res) => res.data)
      .then((data) => {
        console.log("data", data);

        dispatch(setAuthToken(data.data.access_token));
        dispatch({
          type: actionTypes.SET_REGISTER_LOADER,
          payload: false,
        });
        history.push(
          `/${getState().websiteLanguage.language}/zidaccountlinksuccess?$mid=${
            getState().login.mid
          }`,
          { replace: true }
        );
        // history.replace(
        //   `/${getState().websiteLanguage.language}/zidbusinessselect?mid=${
        //     getState().login.mid
        //   }`,
        //   {
        //     replace: true,
        //   }
        // );
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.SET_REGISTER_LOADER,
          payload: false,
        });

        errorMessageHandler(error);
      });
  };
};
