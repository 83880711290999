import { act } from "react-dom/test-utils";
import actionsTypes from "../actionTypes";

const defaultState = {
  loading: false,
  merchant_id: null,
  loadingMerchantInfo: false,
  mid: null, // this is received from the link
  merchant_info: {},
  business: {},
  businessList: {},
  businesslistLoading: false,
  businessDetailLoading: false,
  userLogin: {},
};

/**
 *
 *
 * @param {*} [state=defaultState]
 * @param {*} action
 */
const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionsTypes.SET_LOGIN_LOADER: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actionsTypes.SET_OTP_USER_INFO: {
      return {
        ...state,
        userLogin: action.payload,
      };
    }
    case actionsTypes.SET_BUSINESS_LIST_LOADER: {
      return {
        ...state,
        businesslistLoading: action.payload,
      };
    }
    case actionsTypes.SET_BUSINESS_LIST: {
      return {
        ...state,
        businessList: action.payload,
      };
    }
    case actionsTypes.GET_BUSINESS_DETAIL_LOADER: {
      return {
        ...state,
        businessDetailLoading: action.payload,
      };
    }
    case actionsTypes.SET_BUSINESS_DETAIL: {
      return {
        ...state,
        business: action.payload,
      };
    }
    case actionsTypes.GET_MERCHANT_DETAIL_LOADER: {
      return {
        ...state,
        loadingMerchantInfo: action.payload,
      };
    }
    case actionsTypes.SET_MERCHANT_INFO: {
      return {
        ...state,
        merchant_id: action.payload,
      };
    }
    case actionsTypes.SET_MERCHANT_FULL_INFO: {
      return { ...state, merchant_info: action.payload };
    }
    case actionsTypes.SET_LOCAL_MID: {
      return {
        ...state,
        mid: action.payload,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
