import React from 'react';

const OPLoader = () => {
  return (
    <>
      <div className="op-loader">
        <img 
          src="/assets/img/optimize-loading-spinner.svg"
          alt="Loader"
          width="100px"
          height="100px"
        />
      </div>
    </>
  );
};

export default (OPLoader);      